import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../../../environments/environment';
import { AzureTenant } from '@shared/models/azureTenant';
import { TenantResponse } from '@shared/models/tenantResponse';
import { GetNameOfEmail } from '@shared/util/emailToName';
import { Validator } from '@shared/util/validator';
import { CreateTenantDTO } from '../../models/createTenanatDTO';
import { AddRessources } from '../../services/AddRessource.service';
import { ApiService } from '../../services/api-service/api.service';
import { CreatedApplication } from '../../models/createdApplication';
import { ApplicationSecret } from '../../models/applicationSecret';
import { ErrorService } from '@shared/services/error/error.service';
import { SESSION_STORAGE_KEYS } from 'app/core/session_storage/constants/session-storage-keys.constant';
import { ToastService } from '@shared/services/toast/toast.service';
import { setMsalTenantId } from 'app/core/auth/msal.factory';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  dataFormGroup: UntypedFormGroup;

  tenants: AzureTenant[] = [];

  currentlySelected?: AzureTenant;

  backendConsent: boolean = false;

  frontendConsent: boolean = false;

  backendConsentLink?: string = undefined;

  frontendConsentLink?: string = undefined;

  tenantsToCreate: CreateTenantDTO[] = [];

  onboardingId: string = '';

  validOnboarding: boolean = false;

  //States
  isCurrentlyCreatingApp: boolean = false;

  isAppCreated: boolean = false;

  applicationConsent: boolean = false;

  isCurrentlyCreatingOrganization: boolean = false;

  newOrganizationCreated: boolean = false;

  newOrganizationFailed: boolean = false;

  activeIndex: number = 0;

  atLeastOneConnected: boolean = false;

  constructor(
    private tenantService: AddRessources,
    private route: ActivatedRoute,
    private msalService: MsalService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private _formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
  ) {
    this.route.params.subscribe((s) => {
      this.onboardingId = s.id;
    });

    this.dataFormGroup = this._formBuilder.group({
      orgName: [
        '',
        {
          validators: [Validators.required, Validators.maxLength(30)],
        },
      ],
      licenseKey: ['', [Validators.required, Validators.maxLength(36), Validators.minLength(36)]],
    });
  }

  async ngOnInit(): Promise<void> {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.ONBOARDING_ACTIVE, 'true');
    await this.confirmOnboaring();
    //Loading current state from session storage
    this.loadCurrentState();
  }

  //Validating onboarding id and user
  async confirmOnboaring(): Promise<boolean> {
    if (Validator.validiateId(this.onboardingId)) {
      let email = this.msalService.instance.getAllAccounts()[0].username;
      this.apiService.confirmOnboarding(this.onboardingId, email).subscribe({
        next: (confirm: boolean) => {
          if (confirm) {
            this.validOnboarding = true;
            this.loadTenants();
            return true;
          } else {
            this.toastService.showWarningMessage('You are not allowed to create a new organization');
            return false;
          }
        },
        error: () => {
          this.toastService.showWarningMessage('You are not allowed to create a new organization');
          return false;
        },
      });
    } else {
      this.toastService.showWarningMessage('You are not allowed to create a new organization');
      return false;
    }
  }

  //Get all tenants of user
  loadTenants(): void {
    this.tenantService.getUserTenants().subscribe({
      next: (userTenants: TenantResponse) => {
        let tenants = userTenants.value;
        tenants.forEach((t) => {
          let newTenant = {
            tenantId: t.tenantId,
            displayName: t.displayName,
            tenantCategory: t.tenantCategory,
            organizations: [],
            orgText: '',
            frontendConsent: false,
            backendConsent: false,
            consent: false,
          } as AzureTenant;
          this.tenants.push(newTenant);
        });
      },
    });
  }

  consentToApplicationLink(applicationType: string): void {
    let tenant = this.currentlySelected!;
    let clientID = 'c08e2c98-a937-4642-837e-2105b3296830';

    if (applicationType === 'prod') {
      clientID = 'c08e2c98-a937-4642-837e-2105b3296830';
      this.backendConsent = true;
      sessionStorage.setItem('backendConsent', 'true');
      this.loadCurrentState();
    } else if (applicationType === 'portal') {
      clientID = '223f56ec-09c2-4b08-b484-3e24c518d50c';
      this.frontendConsent = true;
      this.activeIndex++;
      sessionStorage.setItem('frontendConsent', 'true');
      this.loadCurrentState();
    }

    let url =
      'https://login.microsoftonline.com/' +
      tenant.tenantId +
      '/adminConsent?client_id=' +
      clientID +
      '&redirect_uri=' +
      environment.frontendUrl +
      '/consent';
    window.open(url);
  }

  //Generates consent url
  copyConsentLink(backend: boolean): string {
    let tenant = this.currentlySelected!;
    let clientId = 'c08e2c98-a937-4642-837e-2105b3296830';
    if (!backend) {
      clientId = environment.clientId;
    }

    return (
      'https://login.microsoftonline.com/' +
      tenant.tenantId +
      '/v2.0/adminconsent?client_id=' +
      clientId +
      '&scope=openid+profile&redirect_uri=' +
      this.getCurrentConfirmUrl()
    );
  }

  finishTenant(): void {
    this.backToOrg();
  }

  //loads application information
  async getApplicationWithRetry(appId: string): Promise<boolean> {
    try {
      await this.apiService.getApplication(appId).toPromise();
      return true;
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return this.getApplicationWithRetry(appId);
    }
  }

  //redirect to application consent url
  openApplicationPermissionsConsent(): void {
    let tenant = this.tenantsToCreate[this.tenantsToCreate.length - 1];
    window.open(
      'https://login.microsoftonline.com/' +
        tenant.id +
        '/v2.0/adminconsent?client_id=' +
        tenant.clientId +
        '&scope=https://graph.microsoft.com/.default&redirect_uri=' +
        this.getCurrentConfirmUrl(),
      '_blank',
    );
    this.applicationConsent = true;
    this.activeIndex = 3;
  }

  //create application in tenant
  createServicePrincipal(): void {
    if (!this.isAppCreated) {
      sessionStorage.removeItem('tenantsToCreate');

      sessionStorage.setItem('tenantsToCreate', JSON.stringify(this.tenantsToCreate));

      let tenant = this.currentlySelected!;
      setMsalTenantId(tenant.tenantId);
      this.isCurrentlyCreatingApp = true;
      sessionStorage.setItem('isCurrentlyCreatingApp', 'true');
      this.apiService.createApplication(this.getCurrentConfirmUrl()).subscribe({
        next: async (app: CreatedApplication) => {
          sessionStorage.setItem('isCurrentlyCreatingApp', 'false');
          try {
            await this.getApplicationWithRetry(app.id);
            this.apiService.addSecretToServicePrincipal(app.id).subscribe({
              next: async (secret: ApplicationSecret) => {
                let tenantToCreate = {
                  name: tenant.displayName,
                  id: tenant.tenantId,
                  organizations: [],
                  clientId: app.appId,
                  clientSecret: secret.secretText,
                } as CreateTenantDTO;
                this.tenantsToCreate.push(tenantToCreate);
                sessionStorage.setItem('tenantsToCreate', JSON.stringify(this.tenantsToCreate));
                await new Promise((resolve) => setTimeout(resolve, 30000)).then(() => {
                  this.isCurrentlyCreatingApp = false;
                  this.isAppCreated = true;
                  this.activeIndex = 2;
                });
              },
              error: (error: any) => {
                sessionStorage.setItem('isCurrentlyCreatingApp', 'false');
                this.errorService.handleError(error);
              },
            });
          } catch (error: any) {
            this.errorService.handleError(error);
          }
        },
        error: (error: any) => {
          this.errorService.handleError(error);
        },
      });
    }
  }

  //create new org
  finishOnboarding(): void {
    let body = {
      OnboardingId: this.onboardingId,
      CustomerEmail: this.msalService.instance.getAllAccounts()[0].username,
      CustomerName: GetNameOfEmail.getNameOfEmail(this.msalService.instance.getAllAccounts()[0].username),
      OrganizationName: this.dataFormGroup.controls.orgName.value,
      Tenants: this.tenantsToCreate,
      LicenseKey: this.dataFormGroup.controls.licenseKey.value,
    };
    this.isCurrentlyCreatingOrganization = true;
    this.apiService.finishOnboarding(body).subscribe({
      next: (result: any) => {
        this.newOrganizationCreated = result;
        this.isCurrentlyCreatingOrganization = false;
        if (result) {
          sessionStorage.clear();
          sessionStorage.setItem('newOrg', this.dataFormGroup.controls.orgName.value);
        }
      },
      error: () => {
        this.newOrganizationFailed = true;
      },
    });
  }

  isTenantFinished(id: string): boolean {
    for (let index = 0; index < this.tenantsToCreate.length; index++) {
      if (this.tenantsToCreate[index].id == id) {
        return true;
      }
    }
    return false;
  }

  selectedTenant(tenant: AzureTenant): void {
    if (this.dataFormGroup.controls.orgName.valid) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.ONBOARDING_NEW_ORGANIZATION_NAME,
        this.dataFormGroup.controls.orgName.value,
      );
    }
    if (this.dataFormGroup.controls.licenseKey.valid) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.ONBOARDING_LICENSE_KEY, this.dataFormGroup.controls.licenseKey.value);
    }

    sessionStorage.setItem('currentlySelected', JSON.stringify(tenant));
    setMsalTenantId(tenant.tenantId);
    if (localStorage.getItem(SESSION_STORAGE_KEYS.TENANT_ID) !== tenant.tenantId) {
      localStorage.setItem(SESSION_STORAGE_KEYS.TENANT_ID, tenant.tenantId);
      window.location.reload();
    }
    this.currentlySelected = tenant;
    this.backendConsentLink = this.copyConsentLink(true);
    this.frontendConsentLink = this.copyConsentLink(false);
  }

  getBackendConsentLink(): string {
    if (this.backendConsentLink == undefined) {
      this.backendConsentLink = this.copyConsentLink(true);
    }
    return this.backendConsentLink!;
  }

  getFrontendConsentLink(): string {
    if (this.frontendConsentLink == undefined) {
      this.frontendConsentLink = this.copyConsentLink(false);
    }
    return this.frontendConsentLink!;
  }

  cancel(): void {
    if (this.frontendConsent) {
      this.apiService.deleteServicePrincipal(environment.clientId).subscribe({
        next: () => {
          console.log('Frontend App deleted');
        },
        error: () => {
          console.log('Frontend App could not be deleted');
        },
      });
    }
    if (this.backendConsent) {
      this.apiService.deleteServicePrincipal('c08e2c98-a937-4642-837e-2105b3296830').subscribe({
        next: () => {
          console.log('Backend App deleted');
        },
        error: () => {
          console.log('Backend App could not be deleted');
        },
      });
    }
    if (this.isAppCreated) {
      let tenant = this.tenantsToCreate.pop();
      this.apiService.deleteApplication(tenant.clientId).subscribe({
        next: () => {
          console.log('Application deleted');
        },
        error: () => {
          console.log('Application could not be deleted');
        },
      });
    }

    sessionStorage.setItem('frontendConsent', 'false');
    sessionStorage.setItem('backendConsent', 'false');

    this.backToOrg();
  }

  backToOrg(): void {
    if (this.backendConsent && this.frontendConsent) {
      this.tenants.find((value) => value.tenantId === this.currentlySelected.tenantId).consent = true;
      this.atLeastOneConnected = true;
      console.log('Setting atLeastOneConnected to True!');
    }

    if (!this.tenants.some((value) => value.consent === true)) {
      this.atLeastOneConnected = false;
    }

    this.currentlySelected = undefined;
    this.backendConsent = false;
    this.frontendConsent = false;
    this.isAppCreated = false;
    this.applicationConsent = false;
    this.isCurrentlyCreatingApp = false;
    this.backendConsentLink = undefined;
    this.frontendConsentLink = undefined;
    this.activeIndex = 0;

    this.tenants = [...this.tenants];
  }

  navigateToCommunity(): void {
    window.open(
      'https://github.com/whiteducksoftware/cloud-control-community/issues/new?assignees=djkers&labels=Cloud+Control+Portal,help+wanted&title=' +
        this.dataFormGroup.controls.orgName.value +
        ' Onboarding Error',
      '_blank',
    );
  }

  toAdminPortal(): void {
    window.open(environment.frontendUrl + '/admin/dashboard', '_blank');
  }

  getErrorMessage(): string {
    let name = this.dataFormGroup.controls.orgName.value;
    if (name == '') return 'Please enter a name';
    if (name.length > 30) return 'Max. length: 30 characters';
    return '';
  }

  loadCurrentState(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.TENANT_ID);

    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_NEW_ORGANIZATION_NAME) !== null) {
      this.dataFormGroup.controls.orgName.setValue(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_NEW_ORGANIZATION_NAME),
      );
    }

    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_LICENSE_KEY) !== null) {
      this.dataFormGroup.controls.licenseKey.setValue(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.ONBOARDING_LICENSE_KEY),
      );
    }

    if (sessionStorage.getItem('currentlySelected') !== null) {
      this.currentlySelected = JSON.parse(sessionStorage.getItem('currentlySelected')!);
    }

    if (sessionStorage.getItem('frontendConsent') !== null) {
      if (sessionStorage.getItem('frontendConsent') === 'true') {
        this.frontendConsent = true;
        this.activeIndex = 1;
      } else if (sessionStorage.getItem('frontendConsent') === 'false') {
        this.frontendConsent = false;
      }
    }

    if (sessionStorage.getItem('backendConsent') !== null) {
      if (sessionStorage.getItem('backendConsent') === 'true') {
        this.backendConsent = true;
        this.activeIndex = 4;
      } else if (sessionStorage.getItem('backendConsent') === 'false') {
        this.backendConsent = false;
      }
    }

    if (sessionStorage.getItem('tenantsToCreate') !== null) {
      this.tenantsToCreate = JSON.parse(sessionStorage.getItem('tenantsToCreate')!);
    }

    if (sessionStorage.getItem('isCurrentlyCreatingApp') !== null) {
      if (sessionStorage.getItem('isCurrentlyCreatingApp') === 'true') {
        this.isCurrentlyCreatingApp = true;
        this.createServicePrincipal();
      } else if (sessionStorage.getItem('isCurrentlyCreatingApp') === 'false') {
        this.isCurrentlyCreatingApp = false;
      }
    }
  }

  getCurrentConfirmUrl(): string {
    return window.location.origin + '/consent';
  }

  orgNameChange(event: any): void {
    console.log(event.value);
  }
}
